import { render, staticRenderFns } from "./newComManagementData.vue?vue&type=template&id=042861df&scoped=true"
import script from "./newComManagementData.vue?vue&type=script&lang=js"
export * from "./newComManagementData.vue?vue&type=script&lang=js"
import style0 from "./newComManagementData.vue?vue&type=style&index=0&id=042861df&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "042861df",
  null
  
)

export default component.exports